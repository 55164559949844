html,
body,
#root {
  margin: 0;
  height: 100vh;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

.signInBackground {
  background-image: url('../../assets/images/bg.png');
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
}

.pagesBack {
  background-image: url("../images/pagesBack.png");
  /* z-index: -1; */
}

.text-hover-white:hover {
  color: white;
}

.cursor-pointer {
  cursor: pointer;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  /* For Safari */
}


.tox-promotion,
.tox-statusbar__branding {
  display: none;
}


.rounded-bottom-6 {
  border-radius: 0rem 0rem 3rem 3rem;
}